<!-- 影院中电影场次-->
<template>
    <div class="root-wrap fx-column">
        <div v-if="emptyData" class="movie-nosession-wrap">
            <div class="movie-nosession-body">
                <van-image :src="require('@/assets/images/movie/no_session.png')" width="140" height="140"></van-image>
                <div class="movie-nosession-title">暂无场次</div>
            </div>
        </div>
        <div v-if="movieTemp != null" class="header-wrap">
            <div class="cinema_info-wrap--wrap">
                <div class="cinema_info-wrap fx-row fx-between" @click="toCinemaInfo">
                    <div class="cinema_info_txt fx-around">
                        <div class="cinema_name">{{cinemaInfo.name}}</div>
                        <div class="cinema_address">{{cinemaInfo.address}}</div>
                        <div class="cinema_rooms fc-tips">{{cinemaInfo.roomnames}}</div>
                    </div>
                    <!-- <div class="cinema_location fx-middle">
                        <van-image :src="require('@/assets/images/location_icon.png')" width="50" height="50"></van-image>
                    </div> -->
                </div>
            </div>
            <div class="cinema_movie-wrap">
                <movie-swipe v-model="activeIndex" :list="hotMovieList" ref="swipe"></movie-swipe>
                <div v-if="movieTemp != null">
                    <div class="movie_name-wrap fx-center-middle fx-row">
                        <span class="movie_name">{{movieTemp.name}}</span>
                        <span v-show="movieTemp.rating && movieTemp.rating > 0" class="movie_rate">{{movieTemp.rating}}</span>
                    </div>
                    <div class="movie_actor fx-center fx-row fx-middle fc-tips">
                        <div>
                            <span>{{movieTemp.duration}}分钟</span>
                            <span>/</span>
                            <span>{{movieTemp.type}}</span>
                            <span>/</span>
                            <span>{{movieTemp.actors}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="movieTemp != null" class="body-wrap fx-fill">
            <div class="fill-box">
                <van-tabs v-model="activeDateIndex" :scrollspy="false" lazy-render sticky :ellipsis="false" animated>
                    <van-tab v-for="(item, index) in movieDateList" :key="index" :title="$getCalendar(item, 3, 2)">
                        <div v-if="movieSessionEmpty" class="movie-nosession-wrap">
                            <div class="movie-nosession-body">
                                <van-image :src="require('@/assets/images/movie/no_session.png')" width="140" height="140"></van-image>
                                <div class="movie-nosession-title">暂无场次</div>
                                <div v-if="movieDateList.length > 1">
                                    <van-button style="padding: 10px 50px; margin-top: 16px" size="small" @click="doChangeMovieDate">看看其他日期</van-button>
                                </div>
                            </div>
                        </div>
                        <div v-else class="movie_session-wrap">
                            <div class="movie_session-item--wrap" v-for="(item, index) in movieSessionList" :key="index" @click="selectMovieSession(item)">
                                <div class="fx-center-middle">
                                    <span class="fs-14 info_time">{{item.playTime | formatDateToTime}}</span>
                                    <span class="fc-tips">{{item.closeTime | formatDateToTime}}散场</span>
                                </div>
                                <div class="fx-center-middle">
                                    <span class="fs-12 van-ellipsis">{{item.edition}}</span>
                                    <span class="fc-tips">{{item.roomName}}</span>
                                </div>
                                <div class="fx-center-middle">
                                    <template v-if="item.marketPrice != item.price">
                                        <div style="" class="price-grid">
                                            <span class="fs-13 fc-active">￥{{item.price}}</span>
                                            <!-- <span class="fc-tips td-line">{{item.marketPrice}}</span> -->
                                            <!-- <span class="fs-12 sheng">省</span>
                                            <span class="fc-tips">{{formatPrice(item.marketPrice, item.price)}}</span> -->
                                        </div>
                                    </template>
                                    <template v-else>
                                        <span class="fs-14 fc-active">￥{{item.price}}</span>
                                    </template>
                                </div>
                                <div class="fx-center-middle">
                                    <van-button type="primary" size="small">选 座</van-button>
                                </div>
                            </div>
                        </div>
                    </van-tab>
                </van-tabs>
            </div>
        </div>
        <div class="utils-wrap">
        </div>
    </div>
</template>

<script>
import { getCinemaMovieShow } from '@api/cinema-request'
import MovieSwipe from '@/components/cinema/movie-swipe';
import { mapGetters } from 'vuex';
export default {
    data() {
        return {
            cinemaId: this.$route.query.cinemaId,
            movieId: this.$route.query.movieId,
            cinemaInfo: {},
            movieList: [],
            hotMovieList: [],
            activeIndex: -1,
            movieTemp: null,
            activeDateIndex: -1,
            loading: false,
            loadingSession: false,
        }
    },
    computed: {
        ...mapGetters([
            'currentCityCode'
        ]),
        movieDateList() {
            let showList = this.movieTemp?.showList;
            let dateList = showList ? Object.keys(showList) : null;
            return dateList ? dateList : [new Date()];
        },
        movieSessionList() {
            return this.movieTemp.showList?.[this.movieDateList[this.activeDateIndex]] || [];
        },
        movieSessionEmpty() {
            return this.movieSessionList.length > 0 ? false : true;
        },
        emptyData() {
            return this.loading == false && (!this.cinemaInfo || !this.movieTemp);
        }
    },
    watch: {
        activeIndex: {
            handler(val) {
                if (this.hotMovieList && this.hotMovieList.length > 0) {
                    //切换热映电影 清空场次日期信息 清空时间场次信息
                    this.movieTemp = this.hotMovieList[val];
                }
            }
        },
        movieTemp: {
            handler(val) {
                this.activeDateIndex = -1;
            }
        },
    },
    methods: {
        getCinemaMovieShow() {
            this.loading = true;
            let loadingToast = this.$toast.loading('加载中');
            return new Promise((resolve, reject) => {
                getCinemaMovieShow(this.cinemaId).then((res) => {
                    this.hotMovieList = res?.movieList;
                    this.cinemaInfo = res?.cinema;
                    resolve();
                }).finally(() => {
                    this.loading = false;
                    loadingToast.clear();
                })
            })
        },
        //点击场次 去选座
        selectMovieSession(item) {
            //将场次的信息 赋值到cookies 用于在选座时展示部分数据 0为 退出浏览器即清除信息;
            console.log(item);
            this.$cookies.set(this.$config.const.KEY_CURRENT_MOVIE, Object.assign({}, item, { logo: this.movieTemp.logo }), '0');
            this.$goPage('cinema-seat', {
                cinemaName: this.cinemaInfo.name,
                opi: item.id,
                channel: item.channel,
            });
        },
        //当前日期场次为空 点击其他日期被动切换日期
        doChangeMovieDate() {
            let movieDateLength = this.movieDateList.length;
            if (this.activeDateIndex != movieDateLength - 1) {
                this.activeDateIndex++;
            } else {
                this.activeDateIndex = 0;
            }
            this.switchDate();
        },
        toCinemaInfo() {
            if (this.cinemaInfo && Object.keys(this.cinemaInfo).length > 0) {
                this.$goPage('cinema-info', {cid: this.cinemaInfo.id});
                // this.$goPage('location-view', {
                //     name: this.cinemaInfo.name,
                //     address: this.cinemaInfo.address,
                //     lon: this.cinemaInfo.lon,
                //     lat: this.cinemaInfo.lat
                // });
            }
        },
        formatPrice(val, val2) {
            return (val - val2).toFixed(2);
        },
        //初始化页面时获取热映电影 如果通过某电影进来的 优先查询 这里有查询防抖 故watch的movieTemp不会多次查询
        initPage() {
            this.activeIndex = -1;
            this.getCinemaMovieShow().then(() => {
                this.movieTemp = this.hotMovieList[0] || null;
                if (this.movieId) {
                    let movieIndex = -1;
                    this.hotMovieList.forEach((item, index) => {
                        if (item.id == this.movieId) {
                            movieIndex = index;
                            this.$nextTick(() => {
                                this.$refs.swipe.selectMovie(index);
                            })
                        }
                    });
                    if (movieIndex == -1) {
                        this.$toast.allowMultiple();
                        this.$toast({
                            message: '该影院无选中的影片，其他影片一样精彩哦~',
                            duration: 2000,
                        });
                    }
                }
            });
            // this.getCinemaDetail();
            // this.getHotMovieList().then(() => {

            // });
        }
    },
    mounted() {
        this.initPage();
    },
    components: {
        MovieSwipe
    }
}
</script>

<style lang="scss" scoped>
.movie-nosession-wrap {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	.movie-nosession-body {
		margin-bottom: 10%;
		text-align: center;
		color: #949aaa;
		.movie-nosession-title {
			margin-top: 8px;
		}
	}
}
.header-wrap {
	// background: linear-gradient(180deg, #3f4956 0%, #fff 65%);
	.cinema_info-wrap--wrap {
		// padding: 15px;
		// padding-top: 20px;
		// padding-bottom: 2px;
		.cinema_info-wrap {
			background-image: url("~@/assets/images/info_bg.png");
			background-size: cover;
			border-radius: 4px;
			background-position: center;
			padding: 10px 12px;
			padding-bottom: 0px;
			.cinema_info_txt {
				flex: 1;
				overflow: hidden;
				> div {
					padding: 3px 0;
				}
				.cinema_name {
					// font-family: "pf medium";
					font-size: 18px;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}
				.cinema_address {
					color: $second-color;
				}
			}
			.cinema_location {
				flex-shrink: 0;
				padding: 8px;
			}
		}
	}

	.cinema_movie-wrap {
		padding-bottom: 8px;
		position: relative;
		background-color: #fff;
		.movie_name-wrap {
			padding: 4px 0;
			span {
				display: inline-block;
				padding: 2px 6px;
			}
			.movie_name {
				font-size: 15px;
				// font-family: "pf medium";
				color: $second-color;
			}
			.movie_rate {
				font-size: 15px;
				color: $active-color;
			}
		}
		.movie_actor {
			font-size: 12px;
			> div {
				@include txt-ellipsis();
				max-width: 66%;
			}
		}
	}
}

.body-wrap {
	position: relative;
	.movie_session-wrap {
		background-color: $bg-light-color;
		padding: 0px 6px;
		padding-bottom: 8px;

		.movie_session-item--wrap {
			height: 70px;
			display: grid;
			grid-template-columns: 25% 28% 28% 19%;
			background-color: #fff;
			border-radius: 4px;
			padding: 8px 0;
			margin: 6px 0;
			> div {
				> span {
					@include txt-ellipsis();
					display: inline-block;
					padding: 1px 0;
					width: 100%;
					text-align: center;
				}
				&.price_remark span {
					padding: 0;
				}

				.info_time {
					font-weight: 700;
					color: $second-color;
					// font-family: "pf medium";
				}

				.price-grid {
					display: grid;
					grid-template-columns: auto auto;
					align-items: center;
					justify-items: end;
					gap: 0 5px;
					> * {
					}
					.sheng {
						padding: 1px 4px;
						background-color: #f5b225;
						color: #fff;
					}
				}
			}
		}
		.movie_session-empty-wrap {
			margin: 8px 0;
			padding: 20px;
			background-color: #fff;
			border-radius: 4px;
			> div {
				padding: 5px 0;
			}
		}
	}
}

/deep/ .van-tabs {
	height: 100%;
	display: flex;
	flex-direction: column;
	.van-tabs__wrap {
		height: 36px;
		.van-tab__text {
			font-size: 13px;
		}
	}
	.van-tabs__content {
		flex: 1;
		.van-tab__pane {
			height: 100%;
			overflow-y: scroll;
		}
	}
}
</style>
