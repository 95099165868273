<template>
    <div class="component-wrap" style="position: relative;overflow: hidden;padding: 10px 0;">
        <div class="bg" :style="{'background-image': `url(${bgi})`, 'background-size': '100% 100%', 'filter': 'blur(20px)'}"></div>
        <div class="movie-swipe--wrap fx-row" @scroll="handleScroll" ref="movieSwipe" >
            <div class="fx-row fx-end" @touchend="onTouchEnd" @touchcancel="onTouchEnd">
                <div class="cinema_movie-img--wrap" v-for="(item, index) in list" :key="index" ref="image" :style="`width: ${getImgWidth(activeIndex == index)}px`" :class="activeIndex == index ? 'active' : ''">
                    <van-image :src="`${item.logo}_300x300`" @click="selectMovie(index)" width="100%" height="100%" radius="4" fit="cover"></van-image>
                </div>
            </div>
            <div style="border:1px solid red;"></div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        input: Number,
        list: Array,
    },
    data() {
        return {
            activeIndex: 0,
            windowWidth: document.body.clientWidth,
            scrollSwitch: true,
        }
    },
    computed: {
        imgWidth() {
            return (document.body.clientWidth * 0.25);
        },
        bgi() {
            return this.list[this.activeIndex].logo;
        }
    },
    methods: {
        emitIndex() {
            this.$emit('input', this.activeIndex)
        },
        onTouchEnd() {
            this.scrollToIndex(this.activeIndex);
            this.emitIndex();
        },
        selectMovie(index) {
            // this.$nextTick(() => {
            this.activeIndex = index;
            this.scrollToIndex(this.activeIndex);
            this.emitIndex();
            // })
        },
        scrollToIndex(index) {
            let scrollLeft = index * this.imgWidth;
            let movieSwipe = this.$refs.movieSwipe;
            this.scrollSwitch = false;
            movieSwipe.scrollTo({
                left: scrollLeft,
                top: 0,
                behavior: 'smooth'
            });

            let _this = this;
            function setVar() {
                _this.scrollSwitch = true;
            }

            this.$common.throttle(setVar, 100)
        },
        handleScroll() {
            //节流滑动 0.1秒执行一次
            if (this.scrollSwitch) {
                this.calcActiveIndex();
                // this.$common.debounce(this.calcActiveIndex, 65);
                this.scrollSwitch = true;
            }
        },
        calcActiveIndex() {
            let images = this.$refs.image;
            let halfWindowWidth = parseInt(this.windowWidth / 2) - this.windowWidth * 0.28;
            let newImages = images.filter((item) => {
                return item.getBoundingClientRect().left >= halfWindowWidth;
            });
            // console.log(`当前还有${newImages.length}个的左侧距离大于屏幕一半${halfWindowWidth},所以当前activeIndex = ${images.length - newImages.length}`);
            this.activeIndex = images.length - newImages.length;
        },
        getImgWidth(activeFlag) {
            if (activeFlag) return (this.windowWidth * 0.28);
            else return (this.windowWidth * 0.25);
        },
    },
    watch: {
        activeIndex() {
            // this.$common.debounce(() => {
            //     this.$emit('input', this.activeIndex)
            // }, 100);
        }
    }
}
</script>

<style lang="scss" scoped>
.movie-swipe--wrap::-webkit-scrollbar {
	display: none;
}
.movie-swipe--wrap {
	min-width: 100%;
	overflow-x: scroll;
	overflow-y: hidden;
	background-size: 100% 100%;
	> .fx-row {
        float: left;
		height: 150px;
		margin: 0 calc(50% - 28% / 2);
		.cinema_movie-img--wrap {
			padding: 0 4px;
			height: 85%;
		}
		.cinema_movie-img--wrap.active {
			height: 100%;
		}
	}
}

.bg {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	overflow: hidden;
}

.component-wrap {
	&:after {
        position: absolute;
        left: 50%;
        bottom: -5px;
        transform: translate(-50%);
		content: "";
		border-width: 0 7px 12px;
		border-style: solid;
		border-color: transparent transparent #fff transparent;
	}
}
</style>
